import React, { Component } from "react";
import ReactDOM from "react-dom";
import { NavLink } from 'react-router-dom';
import "./HeaderNav.css";
import navJSON from "../../assets/configs/header_navigation.json"; //local .json's are stored in /configs

class HeaderNav extends Component {
    state = {
        activeTab: 0
    };

    constructor(props) {
        super(props);
        this.NavIndicator = React.createRef();
        this.navRefs = [];
    }

    /*
        Component's Output
     */
    render() {
        return (
            <div className="wrapper">
                    <nav>
                        <ul className="fadeInUp">
                            {Object.keys(navJSON.nav).map(index => {
                                return (
                                    <li key={index} ref={ref => (this.navRefs[index] = ref)}>
                                        <NavLink activeClassName="active"  to={navJSON.nav[index]["section"]}>{navJSON.nav[index]["label"]}</NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    <hr ref={elem => { this.NavIndicator = elem; }} />
                </div>
        );
    }

    /*
        Our Mount/Dismount methods
     */
    componentDidMount() {
        for (let i = 0; i < this.navRefs.length; i++) {
           // this.navRefs[i].addEventListener("click", e => this.handleNavClick(e)); // attach our click listeners
        }

        //this.moveIndicator(0); // initiate default state

        window.addEventListener("resize", this.resizeWindow.bind(this)); // monitor window's resizing
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow.bind(this)); // stop monitoring window's resizing
    }

    /*
        Method controls the active Nav Item
     */
    handleNavClick = e => {
        e.preventDefault(); // prevent default action
        this.setState({ activeTab: e.currentTarget.dataset.id }); // update component's state
        this.moveIndicator(e.currentTarget.dataset.id); // now move indicator
    };

    /*
        Method responsible for moving our Nav Indicator
     */
    moveIndicator(elementId) {
        const target = ReactDOM.findDOMNode(this.NavIndicator);
        const thisElem = ReactDOM.findDOMNode(this.navRefs[elementId]);

        this.navRefs.forEach(function(elem) {
            // reset active items
            if (elem.classList.contains("active")) {
                elem.classList.remove("active");
            }
        });

        thisElem.classList.add("active"); // mark nav item as active

        // pass choice up to parent app
        this.props.onChange(
            navJSON.nav[elementId]["label"],
            navJSON.nav[elementId]["offset"]
        );

        const width = thisElem.getBoundingClientRect().width;
        const height = thisElem.getBoundingClientRect().height - 7;
        const left = thisElem.getBoundingClientRect().left + window.pageXOffset;
        const top = thisElem.getBoundingClientRect().top + window.pageYOffset;

        target.style.width = `${width}px`;
        target.style.height = `${height}px`;
        target.style.left = `${left}px`;
        target.style.top = `${top}px`;
        target.style.transform = "none";
    }

    /*
        Update our Nav Indicator on Window Resize
     */
    resizeWindow() {
        const active = document.querySelector("header .active");

        if (active) {
            const left = active.getBoundingClientRect().left + window.pageXOffset;
            const top = active.getBoundingClientRect().top + window.pageYOffset;
            const target = ReactDOM.findDOMNode(this.NavIndicator);

            target.style.left = `${left}px`;
            target.style.top = `${top}px`;
        }
    }
}

export default HeaderNav;