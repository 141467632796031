import React from 'react';
import './HeroVideo.css';
import { Player } from 'video-react';

export default (props) => {
    return (
        <Player className="heroVid"
            fluid
            muted
            playsInline
            autoPlay
            loop
            controls={false}
        >
            <source src="https://austincameron.com/ewpc/src/assets/videos/homepage_color.mp4" />
        </Player>
    );
};