import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Switch, Route, NavLink, BrowserRouter as Router } from 'react-router-dom'
import HomePage from './pages/HomePageMinimal/HomePage';
import AdminPage from './pages/AdminPage/AdminPage';

import Header from './components/Header/MinimalHeader';
import Footer from './components/Footer/MinimalFooter';

class App extends Component {
  render() {
    return (
        <>
        <Router basename={process.env.PUBLIC_URL}>

        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/edit" component={AdminPage} />
        </Switch>
            <Footer/>
        </Router>
        </>
    );
  }
}

export default App;