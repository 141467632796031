import React, { Component } from 'react';
import './FixedCountdownBar.css';
import { NavLink, Link } from 'react-router-dom';

class FixedCountdownBar extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="fixedCountdownBar">
            <div className="sub_container">
             <iframe width="260" height="89" style={{margin:'5px 0 5px 0',padding:0}} src="https://venue.streamspot.com/countdown/8e62b93e?inverse=true" frameborder="0" scrolling="no"></iframe>

                <div className="ctas">
                    <a target="_blank" href="https://www.bobrodgersministries.org/video-archive" className="cta">View Archive</a>
                </div>
            </div>
             </div>
        )
    }
}

export default FixedCountdownBar;