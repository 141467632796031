import React, { Component } from 'react';
import './Footer.css';
import UnderConstructionBar from '../../components/UnderConstructionBar/UnderConstructionBar';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="sub_container">
                    <div className="social_bar fadeInUp">

                    </div>
                    <div className="top">

                        <section className="text_header fadeInUp" ref={this.donateRef}>
                            <h5>Call for Information</h5>
                        </section>
                        <h3><a target="_blank" href="tel:15029643304">502-231-9100</a></h3>
                        <h3><a target="_blank" href="mailto:info@ewpc.us">info@ewpc.us</a></h3>
                        <hr/>
                        <h3><a target="_blank" href="https://bobrodgersministries.org">Click Here for Bob Rodgers Ministries</a></h3>


                        <div className="footer_nav fadeInUp">



                        </div>
                    </div>

                    <UnderConstructionBar/>
                    <div className="bottom fadeInUp">
                        <p>6900 Billtown Rd, Louisville, KY 40299  |  <a href="tel:15022319100" className="number">(502) 231-9100</a></p>
                        <p className="sub">© 2020 Evangel World Prayer Center. All Rights Reserved  |  <a href="#">Privacy Policy</a></p>
                        <p className="copyright">Developed by <a href="https://AustinCameron.com" target="_blank" rel="noopener noreferrer">Austin Cameron</a>.</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;