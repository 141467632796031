import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
//my components
import HeroVideoOutput from '../../components/HeroVideo/HeroVideo';
import SmallMenuBar from '../../components/SmallMenuBar/OldSmallMenuBar';
import FixedCountdownBar from '../../components/FixedCountdownBar/FixedCountdownBar';
import StillOpenBar from '../../components/UnderConstructionBar/StillOpenBar';
import UnderConstructionBar from '../../components/UnderConstructionBar/UnderConstructionBar';
import Header from '../../components/Header/MinimalHeader';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';


//third party
import "../../../node_modules/video-react/dist/video-react.css"; // import css
import LazyLoad from 'react-lazyload';

const styles = theme => ({
    containedPrimary: {
        backgroundColor: '#1C80C4'
    }
});
class HomePage extends Component {
    constructor() {
        super();
        this.state = { latestFbId: "1431352177071916" }; // 235301357598997
        this.scrollToVideo = this.scrollToVideo.bind(this);
        this.scrollToDonate = this.scrollToDonate.bind(this);
        this.videoRef = React.createRef();
        this.donateRef = React.createRef();
    }

    componentWillMount() {
        /*
        var access_token = "EAAI5I6FrZBAMBAE5c3mZB2rgh9j3ZC2PpJdnmclpJc1w6ehiBDbEQFbbvaWrZBTZATPLoOj3YhydIZCCJPaJyIOy5ma5lXIdeoGn3ta01kbwoDgIam1Hu4TAniQ9NmUZAyMs7hwKTChX7SvZBX0FQ5dXTYF7KIHNwsAZC5d63PF1R3QZDZD";
        const apiURL = "https://graph.facebook.com/v6.0/evangelworldprayercenter/videos?fields=id&limit=1&access_token="+access_token;
        return fetch(apiURL, {cache: "no-store"})
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson){
                    this.setState({ latestFbId:responseJson.data[0].id });
                }else{
                    //logged out
                }
            })
            .catch((error) => {
                console.error(error);
            });
*/
        const apiURL = "https://worldprayercenter.org/ewpc.com/settings.json";
        return fetch(apiURL, {cache: "no-store"})
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson){
                    this.setState({ latestFbId:responseJson.fb_id });
                }else{
                    //logged out
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }
    componentDidMount() {

    }
    scrollToVideo() {
        window.scrollTo({
            top: this.videoRef.current.offsetTop-100,
            behavior: 'smooth',
        })
     }
    scrollToDonate() {
                window.scrollTo({
                    top: this.donateRef.current.offsetTop-50,
                    behavior: 'smooth',
                })
     }

    render() {
        const { latestFbId } = this.state;
        return (<>
            <Header fixed={true}/>
            {/*
        <StillOpenBar/>
             */}
            <div className="ewpc">
            <div className="hero_vid" style={{
            width: '100%',
                height: 'auto',
                maxHeight: '800px',
                overflow: 'hidden',
                z_index: '1',
                position: 'relative'
        }}>
    <div className="hero_copy">
        <h2>Reaching a City.</h2>
        <h3>Impacting the World.</h3>
        <div className="ctas">
            <a onClick={this.scrollToVideo} className="cta">Watch Now</a>
            <a onClick={this.scrollToDonate} className="cta">Give</a>
        </div>


            </div>

                <HeroVideoOutput/>
            </div>

                <section className="text_header fadeInUp">
                    <h5 style={{fontSize:38,marginBottom:40}}>WELCOME TO EWPC</h5>
                </section>
            <div className="about_text_header">
                <h2 className="fadeInUp">We are a church with 1 main campus<br/>and 7 satellite churches.<br/>Find one near you and visit us this weekend!</h2>
                <a target="_blank" href="https://worldprayercenter.org/locations" className="cta fadeInUp" style={{display:'none'}}>Browse Locations</a>

        </div>

                <section className="text_header fadeInUp">
                    <h5 style={{fontSize:30,marginBottom:40}}>From the Desk of Dr. Bob Rodgers:</h5>
                </section>

        <section className="homepage_video" ref={this.videoRef}>
            <section className="text_header fadeInUp">
                <h5 style={{marginTop:-30,fontSize:38,marginBottom:40,color:'white'}}>WATCH THE LATEST SERMON</h5>
            </section>
            <div className="fadeInUp" style={{textAlign:'center'}}>
                {
                    (latestFbId!=null) ?
                        <LazyLoad height={400} once>
                            <iframe width="1000" height="551" src={"https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEvangelWorldPrayerCenter%2Fvideos%2F"+latestFbId+"%2F&show_text=0&autoplay=1&width=560"} frameborder="0"></iframe>
                        </LazyLoad> :
                        <LazyLoad height={400} once>
                            <iframe width="1000" height="551" src="https://player2.streamspot.com/?playerId=a2c112a9" frameborder="0"></iframe>
                        </LazyLoad>
                }

                <h2 style={{width:'60%'}}>"Our Mission is to Reach the Community & Impact the World with the Gospel of Jesus Christ."</h2>
            </div>
            </section>
                {/*<SmallMenuBar scrollToDonate={this.scrollToDonate}/>*/}
                <section className="text_header fadeInUp">
                    <h5>UPCOMING EVENTS</h5>
                </section>
                <div className="fadeInUp">
                    <iframe className="fbEvents" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fevangelworldprayercenter%2F&tabs=timeline,events&width=360&height=600&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=625775148267523" style={{width:360,height:600,textAlign:'center',border:"none",overflow:"hidden",margin:'40px auto 20px',display:'block'}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                </div>
                <section className="text_header fadeInUp" ref={this.donateRef}>
                    <h5>TITHE & OFFERINGS</h5>
                </section>

                <Button onClick={()=>{window.open('https://app.securegive.com/WorldPrayerCenter/')}} variant="contained" color="primary" style={{margin:'100px auto 100px',display:'block',backgroundColor: '#1C80C4'}}  classes={{ primary: styles.containedPrimary }}>
                    Open Secure Donation Portal &nbsp;
                    <LockIcon style={{marginBottom:'-5px'}}/>
                </Button>
                {/*
                <div className="fadeInUp" style={{overflowY:'scroll'}}>
                    <iframe className="titheExternal" src="https://app.securegive.com/WorldPrayerCenter/evangel-world-prayer-ctr/donate/category" style={{width:'90%',height:1200,position:'relative',textAlign:'center',border:"none",overflow:"hidden",margin:'auto auto -50px auto',display:'block'}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                </div>
                 */}

            </div>
            <FixedCountdownBar/>
            </>
    );
    }
}

export default HomePage;
