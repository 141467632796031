import React, { Component } from 'react';
import './FullScreenMenu.css';

class TiltedMenu extends Component {
    render() {
        return (
            <div id="tilted_menu" className={ this.props.show ? "tilted_menu active" : "tilted_menu" }>
                <div className={ this.props.show ? "menu_content_container fadeInUp active" : "menu_content_container fadeInUp" }>
                <div className="menu_featured_content">
                    <div className="row">
                        <h3>Featured Locations</h3>
                        <div className="box">
                            <a href="#">
                            <img className="pic" src={require("../../assets/images/locations/billtown.jpg")} />
                            <h4>Billtown Campus</h4>
                            </a>
                        </div>
                        <div className="box">
                            <a href="#">
                            <img className="pic" src={require("../../assets/images/locations/minors.jpg")} />
                            <h4>Minors Lane Campus</h4>
                            </a>
                        </div>
                        <div className="box">
                            <a href="#">
                            <img className="pic" src={require("../../assets/images/locations/City-of-Hope.jpg")} />
                            <h4>City of Hope</h4>
                            </a>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="row">
                        <h3>Our Ministries</h3>

                        <div className="box">
                            <a href="https://bobrodgersministries.org">
                                <img className="pic" src={require("../../assets/images/locations/bob-ministry.jpg")} />
                                <h4>Bob Rodger's Ministries</h4>
                            </a>
                        </div>
                        <div className="box">
                            <a href="https://thelordskitchen.com">
                                <img className="pic" src={require("../../assets/images/locations/lords-kitchen.jpg")} />
                                <h4>Lord's Kitchen</h4>
                            </a>
                        </div>
                        <div className="box">
                            <a href="#">
                                <img className="pic" src={require("../../assets/images/locations/prison.jpg")} />
                                <h4>Prison Ministry</h4>
                            </a>
                        </div>
                        <div className="clear"></div>
                        <div className="box">
                            <a href="https://evangelchristianschool.com">
                                <img className="pic" src={require("../../assets/images/locations/evangel-school.jpg")} />
                                <h4>Evangel Christian School</h4>
                            </a>
                        </div>
                        <div className="box">
                            <a href="#">
                                <img className="pic" src={require("../../assets/images/locations/City-of-Hope.jpg")} />
                                <h4>Evangel Christian College</h4>
                            </a>
                        </div>
                        <div className="box">
                            <a href="https://wordmediagroup.com">
                                <img className="pic" src={require("../../assets/images/locations/word-alive.jpg")} />
                                <h4>Word Broadcasting</h4>
                            </a>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                <div className="menu_content">
                    <ul>
                        <li><a className="active" href="#">Home</a></li>
                        <li><a className="" href="#">Tithe & Offerings</a></li>
                        <li><a className="" href="#">Locations & Services</a></li>
                        <li><a className="" href="#" target="_blank" rel="noopener noreferrer">Upcoming Events</a></li>
                        <li><a className="" href="#">Our Pastors & Staff</a></li>
                        <li><a className="" href="#">Our Beliefs</a></li>
                        <li><a href="#">Prayer Requests</a></li>
                        <li className="cta"><a href="https://bobrodgersministries.org/bobrodgersnetwork">Watch Sermons</a></li>
                    </ul>
                </div>
            </div>
            </div>
        )
    }
}

export default TiltedMenu;
