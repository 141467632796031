import React, { Component } from 'react';
import './Header.css';
import Hamburger from '../Hamburger/Hamburger';
import HeaderNav from '../HeaderNav/HeaderNav';
import FullScreenMenu from '../FullScreenMenu/FullScreenMenu';
import { Link } from 'react-router-dom'

class Header extends Component {
    constructor() {
        super();
        this.state = { showMenu:false};
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    toggleMenu(){
        this.setState({ showMenu: !this.state.showMenu });

    };
    render() {
        if(this.props.fixed){
            var cls="fixed";
        }
        return (
            <header className={cls+' minimal'}>
                    <a href="https://worldprayercenter.org/">
                        <div className="logo">
                    </div>
                    </a>
            </header>
        )
    }
}

export default Header;