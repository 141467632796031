import React, { Component } from 'react';
import './SmallMenuBar.css';
import { NavLink, Link } from 'react-router-dom';

class SmallMenuBar extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="small_menu_bar">
                <div className="sub_container">
                            <ul className="fadeInUp">
                                <li><a target="_blank" href="https://www.bobrodgersministries.org/video-archive">Sermons</a></li>
                                <li><a target="_blank" href="http://www.worldprayercenter.org/locations">Locations</a></li>
                                <li><a target="_blank" href="https://bobrodgersministries.org">BRM</a></li>
                                <li><a onClick={this.props.scrollToDonate}>Giving</a></li>
                                <li><a target="_blank" href="tel:15029643304">Prayer Requests</a></li>
                            </ul>
                        </div>
             </div>
        )
    }
}

export default SmallMenuBar;