import React, { Component } from 'react';
//import MapMarker from '../../components/MapMarker/MapMarker';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';



class AdminPage extends Component {

    constructor() {
        super();
        this.state={
            pw:"",
            fb_id:""
        }
        this.saveFbID = this.saveFbID.bind(this);
    }
    componentWillMount(){
        var apiURL = 'https://worldprayercenter.org/ewpc.com/settings.json';
        return fetch(apiURL, {cache: "no-store"})
            .then((response) => response.json())
            .then((responseJson) => {
                const streaming=responseJson.streaming;
                const fb_id=responseJson.fb_id;
                    this.setState({streaming:streaming, fb_id:fb_id});
            })
            .catch((error) => {
                console.error(error);
            });
    }
    saveFbID(){
        var apiURL = 'https://worldprayercenter.org/ewpc.com/update.php?fb_id='+this.state.fb_id;
        return fetch(apiURL, {cache: "no-store"})
            .then((response) => response.json())
            .then((responseJson) => {
                const response=responseJson;
            })
            .catch((error) => {
                console.error(error);
            });
    }
    render() {
        return (
            // Important! Always set the container height explicitly
            <>
            <div style={{ height: '600px', width: '50%',textAlign:'center',margin:'auto' }}>
                {(this.state.pw=="6900BilltownRoad")
                    ?
                    <>
                    <h2 className="location_sidebar_header">Edit Video Settings</h2>
                    <TextField
                        required
                        id="outlined-required"
                        label="Facebook Video ID"
                        value={this.state.fb_id}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        onChange={(event)=>{this.setState({'fb_id': event.target.value.replace(/[^0-9]/g, '')})}}
                    />

                    <Button onClick={this.saveFbID} variant="contained" color="primary" style={{marginTop:10,marginBottom:8}}>
                        Update &nbsp;
                        <SaveIcon />
                    </Button>
                    </>
                    :
                    <>
                    <h2 className="location_sidebar_header">Login</h2>
                    <TextField
                        required
                        id="outlined-required"
                        label="Enter Password"
                        value={this.state.pw}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        onChange={(event)=>{
                                                this.setState({'pw': event.target.value});
                                                }}
                        type={'password'}
                    />
                    </>
                }

            </div>
            </>
        );
    }
}

export default AdminPage;