import React, { Component } from 'react';
import './UnderConstructionBar.css';
import { NavLink, Link } from 'react-router-dom';

class StillOpenBar extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="UnderConstructionBar stillopen">
                <div className="sub_container">
                    <h3 className="fadeInUp">
                        Watch us online till further notice..
                        <div className="ctas">
                            <a target="_blank" href="https://www.facebook.com/EvangelWorldPrayerCenter/photos/a.117712067290/10157174420232291/?type=3&theater" className="cta">Learn More</a>
                        </div>
                    </h3>

                </div>
            </div>
        )
    }
}

export default StillOpenBar;