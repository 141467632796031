import React, { Component } from 'react';
import './UnderConstructionBar.css';
import { NavLink, Link } from 'react-router-dom';

class UnderConstructionBar extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="UnderConstructionBar">
                <div className="sub_container">
                    <h3 className="fadeInUp">
                        Our Site is Under Construction, More Updates Soon!
                    </h3>
                </div>
            </div>
        )
    }
}

export default UnderConstructionBar;